import {createContext, useContext, useEffect, useState} from "react";
import React from "react";
import {useLocation} from "react-router-dom";

interface ErrorContextType {
    currentError: any,
    setCurrentError: (error: any) => void
}

const ErrorContext = createContext<ErrorContextType>({} as ErrorContextType);

export const ErrorService: React.FC = ({children}) => {
    const [currentError, setCurrentError] = useState();
    const location = useLocation();


    useEffect(() => {
        setCurrentError(undefined);
    }, [location])

    return (
            <ErrorContext.Provider value={{currentError, setCurrentError}}>
                {children}
            </ErrorContext.Provider>
    );
}

export default function useError() {
    return useContext(ErrorContext)
}