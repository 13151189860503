import React from "react";
import useAuth from "./AuthService";
import {Navigate} from "react-router-dom";

interface Props {
    component: React.ComponentType
    path?: string
    roles: any
}

export const PrivateRoute: React.FC<Props> = ({component: RouteComponent, roles}) => {
    const {authentication} = useAuth();
    const r = roles;

    if (authentication) return <RouteComponent/>

    return <Navigate to={'/login'}/>
}