import React, {useEffect, useState} from "react";
import {Button, Col, Container, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import useOfficials from "../../services/OfficialContext";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCity, faEdit} from "@fortawesome/free-solid-svg-icons";
import HierarchyBradCrumbs from "../../components/region/HierarchyBradCrumbs";
import {Hierarchy} from "../../esm";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import API from "../../../Request/API";

const RegionDetailPage: React.FC = () => {
    const {officials, region, updateLastChecked, fetchOfficialsByRegionId} = useOfficials();
    const [lowestLevelHierarchy, setLowestLevelHierarchy] = useState<Hierarchy | undefined>();
    const lastLevelHierarchy = lowestLevelHierarchy;
    const {id} = useParams();
    const navigate = useNavigate();


    // Fetch new data
    useEffect(() => {
        fetchOfficialsByRegionId(Number(id));
        // jump to top of page
        // window.scrollTo(0, 0)
    }, [id]);


    // Reload for state
    useEffect(() => {
        if (region?.hierarchyTree === undefined)
            return;

        // Clone Array and get last element
        setLowestLevelHierarchy([...region.hierarchyTree].slice(-1).pop());
    }, [officials, region]);

    const handleReviewCheckbox = (idx) => {

        const official = officials.find(official => official.official_id === idx);

        if (!official)
            return;

        official.last_reviewed = (official.last_reviewed) ? undefined : (new Date());

        API.post('/api/updateContact', official, {withCredentials: true})
                .then((res) => {
                    console.log(res.data)
                    fetchOfficialsByRegionId(Number(id));
                })
    }

    const tableContainerStyles = {
       maxWidth: '80vw'
    };

    return (
            <section className="pt-6 pt-md-8 pb-8 mb-md-8">
                <Container style={tableContainerStyles}>
                    <Row>
                        <Col className="col-12">
                            <Row className="mb-6 mb-md-8">
                                {region?.hierarchyTree && <Col sm={12}>
                                    <HierarchyBradCrumbs hierarchyTree={region.hierarchyTree}/>
                                </Col>
                                }

                                <div className="col-auto">
                                    <div className="icon-circle bg-primary text-white">
                                        <FontAwesomeIcon icon={faCity}/>
                                    </div>

                                </div>
                                <div className="col ms-n4">
                                    <h2 className="fw-bold mb-0">
                                        {lastLevelHierarchy?.name}
                                    </h2>

                                    {region?.lastChecked && <p className={'m-0 p-0'} style={{
                                        lineHeight: '1.05',
                                        fontSize: '1rem'
                                    }}>
                                        {region.lastChecked}<br/>
                                        {region.user?.username}
                                    </p>}
                                </div>
                                <div className="col ms-n4">
                                    <h3 className="fw-bold mb-0">Adresse </h3>

                                    {(region?.street || region?.plz || region?.city) && <p>
                                        {region?.street && <><span>{region.street}</span><br/></>}
                                        {region?.plz && <><span>{region.plz}</span></>}{region?.city && <span> {region.city}</span>}<br/>
                                    </p>}

                                    {(!region?.street && !region?.plz && !region?.city) && <p>
                                        Es wurde bisher keine Addresse hinterlegt.
                                    </p>}

                                    {(region?.www) && <a href={'http://' + region?.www} target={'_blank'} className="fs-lg text-gray-700 mb-0">
                                        {region?.www}
                                    </a>}
                                </div>
                            </Row>

                            <Row>
                                <Col>
                                    {region?.www && <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>Auf Google suchen</Tooltip>
                                            }
                                    >
                                        <Button href={`https://www.google.com/search?q=site:${region?.www}+wirtschaftsförderung+leiter -filetype:pdf`}
                                                variant={'outline-secondary'}
                                                target={'_blank'}
                                                size={'sm'}
                                                className={'float-end ms-5'}
                                        >
                                            <FontAwesomeIcon icon={faGoogle}/>
                                        </Button>
                                    </OverlayTrigger>}
                                    <Button href={`/region/${id}/contact/add`}
                                            variant={'outline-dark'}
                                            size={'sm'}
                                            className={'float-end ms-5'}
                                    >Neuen Kontakt anlegen
                                    </Button>

                                </Col>
                            </Row>
                            <Table>
                                <thead>
                                <tr>
                                    <th className={'px-4 pb-3'}>Geschlecht</th>
                                    <th className={'px-4 pb-3'}>Name</th>
                                    {/*<th className={'px-4 pb-3'}>Nachname</th>*/}
                                    <th className={'px-4 pb-3'}>Position</th>
                                    <th className={'px-4 pb-3'}>Telefon</th>
                                    <th className={'px-4 pb-3'}>Email</th>
                                    <th/>
                                </tr>
                                </thead>

                                <tbody>

                                {officials.map(official => {
                                    return (
                                            <tr key={official.official_id}>
                                                <td className={'p-4'}>{official.sex}</td>
                                                <td className={'p-4'}>{official.name}</td>
                                                {/*<td className={'p-4'}>{official.lastname}</td>*/}
                                                <td className={'p-4'}>{official.position}</td>
                                                <td className={'p-4'}>{official.phone}</td>
                                                <td className={'p-4'}>{official.email}</td>

                                                <td className={'py-0 px-4'}>
                                                    <Row>
                                                        <OverlayTrigger
                                                                key={'top'}
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>Review Status</Tooltip>
                                                                }
                                                        >
                                                            <Col md={3} className={'p-1 my-auto'}>
                                                                <input type={'checkbox'}
                                                                       checked={!!(official.last_reviewed)}
                                                                       onChange={() => handleReviewCheckbox(official.official_id)}/>
                                                            </Col>
                                                        </OverlayTrigger>
                                                        <Col md={9} className={'p-3'}>
                                                            <Button onClick={() => navigate(`/contact/${official.official_id}`)}
                                                                    variant={'secondary'}
                                                                    size={'sm'}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                    )
                                })}
                                </tbody>
                            </Table>

                            {id && <Col className={'mt-7'}>
                                <Button variant={'success'}
                                        className={'float-end ms-5'}
                                        onClick={() => {
                                            updateLastChecked(Number(id));
                                            window.scrollTo(0, 0)
                                        }}
                                >Alles ok</Button>
                            </Col>}
                        </Col>
                    </Row>
                </Container>
            </section>
    );
}

export default RegionDetailPage