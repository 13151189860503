import React, {useEffect} from 'react';
import useError from "../../services/ErrorContext";
import {Card, Container} from "react-bootstrap";

const ErrorContainer: React.FC = () => {
    const {currentError} = useError();

    // Rerender if currentError is changing
    useEffect(() => {
    }, [currentError])

    return (
            <>
                {currentError && <Container className={"my-5"}>
                    <Card className="card-border card-border-lg border-danger">
                        <div className="card-body">
                            <h3>{currentError.statusText}</h3>
                        </div>
                    </Card>
                </Container>
                }
            </>
    );
}

export default ErrorContainer;