import React from "react";
import '../assets/css/theme.bundle.css'
import '../assets/css/libs.bundle.css'
import '../assets/css/error.css'
import '../assets/css/app.css'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {AppRoutes} from "../../Routes";
import {NavigationBar} from "../components/Navigation/NavigationBar";
import {AuthService} from "../../Auth/services/AuthService";
import Footer from "../components/Footer/Footer";
import {ErrorService} from "../services/ErrorContext";
import ErrorContainer from "./error/ErrorContainer";

const App: React.FC = () => {
    return (
            <>
                <ErrorService>
                    <AuthService>
                        <ToastContainer
                                position="bottom-right"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                        />

                        <div>
                            <NavigationBar/>

                            <ErrorContainer/>

                            <AppRoutes/>
                        </div>
                    </AuthService>
                    <Footer/>
                </ErrorService>
            </>
    )
            ;
}

export default App;
