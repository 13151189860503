import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import API from "../../../Request/API";
import {useNavigate, useParams} from "react-router-dom";
import TextEditor from "../../components/Editor/TextEditor";
import useError from "../../services/ErrorContext";
import useOfficials from '../../services/OfficialContext';

const OfficialPage: React.FC = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {region} = useOfficials();
    const {setCurrentError} = useError();
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string | undefined>();

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm({mode: "onChange"});

    const {contact} = watch();

    const handleSubmitContact = () => {
        contact.email = (contact.email === 'null') ? null : contact.email

        API.post('/api/updateContact', contact, {withCredentials: true})
                .then(res => {
                    navigate(`/region/${region?.geoId}`);
                })
                .catch(error => {
                    setCurrentError(error.response)
                })
    }

    const handleDeleteContact = () => {
        console.log(contact)
        API.post('/api/contact/delete', {'official_id': contact.official_id}, {withCredentials: true})
                .then(res => {
                    navigate(`/region/${region?.geoId}`);
                })
                .catch(error => {
                    setCurrentError(error.response)
                })
    }

    // Fetch data and set form values
    useEffect(() => {
        API.get(
                `/api/contact/${id}`,
                //{withCredentials: true}
        ).then(res => {
            const payload = res.data.payload;
            setValue('contact', payload.official);
            setText(payload.text);
            setLink(payload.link);
            window.scrollTo(0, 0);
        }).catch(error => {
            setCurrentError(error.response)
        }).finally(() =>
                // jump to top of page
                window.scrollTo(0, 0)
        );
    }, []);

    return (
            <>
                <section className='pt-6 pt-md-8 pb-8 mb-md-8'>
                    <Container fluid className="d-flex flex-column">
                        <Row className=" justify-content-center gx-0">
                            <Col sm={6}>
                                <Container>
                                    <h2 className={'mt-5'}>Kontakt bearbeiten</h2>
                                    <Form className="mb-6" onSubmit={handleSubmit(handleSubmitContact)}>
                                        <Form.Group className={'my-3'}>
                                            {/* Firstname */}
                                            <Form.Label
                                                    className="form-label"
                                                    htmlFor="name"
                                            >
                                                Vorname
                                            </Form.Label>
                                            <Form.Control
                                                    {...register('contact.name')}
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Vorname des Kontaktes"
                                                    autoComplete="off"
                                            />
                                            {errors?.name && <p className={"error"}>{errors?.name}</p>}

                                        </Form.Group>

                                        <Form.Group className={'my-3'}>
                                            <Form.Label className="form-label" htmlFor="sex">
                                                Geschlecht
                                            </Form.Label>
                                            <Form.Select
                                                    {...register('contact.sex')}
                                                    aria-label="Default select example"
                                                    id={"sex"}
                                            >

                                                {/*<option value="keineAngabe">Keine Angabe</option>*/}
                                                <option value="u">Keine Angabe</option>
                                                <option value="m">Männlich</option>
                                                <option value="f">Weiblich</option>
                                            </Form.Select>
                                        </Form.Group>


                                        <Form.Group className={'my-3'}>
                                            <Form.Label className="form-label" htmlFor="position">
                                                Position
                                            </Form.Label>
                                            <Form.Control
                                                    {...register('contact.position')}
                                                    type="text"
                                                    className="form-control"
                                                    id="position"
                                                    placeholder="Position des Kontaktes"
                                                    autoComplete="off"/>
                                            {errors?.contact?.position && <p className={"error"}>{errors?.contact?.position.message}</p>}
                                        </Form.Group>

                                        <Form.Group className={'my-3'}>
                                            <Form.Label className="form-label" htmlFor="phone">
                                                Telefon
                                            </Form.Label>
                                            <Form.Control
                                                    {...register('contact.phone')}
                                                    type="tel"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Telefonnummer des Kontaktes"
                                                    autoComplete="off"/>
                                            {errors?.contact?.phone && <p className={"error"}>{errors?.contact?.phone.message}</p>}
                                        </Form.Group>

                                        <Form.Group className={'my-3'}>
                                            <Form.Label className="form-label" htmlFor="email">
                                                E-Mail
                                            </Form.Label>
                                            <Form.Control
                                                    {...register('contact.email')}
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="E-Mail des Kontaktes"
                                                    autoComplete="off"/>
                                            {errors?.contact?.email && <p className={"error"}>{errors?.contact?.email.message}</p>}
                                        </Form.Group>

                                        <div>
                                            <Button variant={'secondary'}
                                                    disabled={errors.password || errors.username}
                                                    className={'float-end ms-5'}
                                                    type={'submit'}
                                            >
                                                Speichern
                                            </Button>

                                            <Button variant={'outline-danger'}
                                                    onClick={() => {
                                                        if (window.confirm('Wollen Sie diesen Kontakt wirklich löschen?')) handleDeleteContact()
                                                    }}
                                                    disabled={errors.password || errors.username}
                                                    className={'float-end ms-5'}
                                            >
                                                Löschen
                                            </Button>
                                            <Button variant={'outline-info'}
                                                    onClick={() => navigate(-1)}
                                                    className={'float-start'}
                                                    type={'button'}
                                            >
                                                Zurück
                                            </Button>

                                        </div>
                                    </Form>
                                </Container>
                            </Col>
                            <Col sm={6} className={'d-flex align-items-center'}>
                                <Container>
                                    {text && <>
                                        <TextEditor disabled={true} text={text} link={link}/>
                                        <Row className={'d-flex mt-5'}>
                                            <Col>
                                                <Button href={`/contact/${id}/edit`}
                                                        variant={'secondary'}
                                                        size={'sm'}
                                                >
                                                    Annotieren
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                    }

                                    {!text &&
                                            <Row className={'d-flex mt-5 text-center'}>
                                                <Col>
                                                    <p>Es ist kein Text für diesen Ansprechpartner hinterlegt.</p>
                                                </Col>
                                            </Row>
                                    }
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
    );
}

export default OfficialPage;