import React from "react";
import {Route, Routes} from "react-router-dom";
import {PrivateRoute} from "./Auth/services/PrivateRoute";
import LoginPage from "./Auth/pages/LoginPage";
import LandingPage from "./OfficialMining/pages/Landing";
import EditorPage from "./OfficialMining/pages/EditorPage";
import OfficialPage from "./OfficialMining/pages/official/OfficialPage";
import Error404Page from "./OfficialMining/pages/error/Error404";
import RegionDetailPage from "./OfficialMining/pages/region/RegionDetailPage";
import {OfficialService} from "./OfficialMining/services/OfficialContext";
import CreateOfficialPage from "./OfficialMining/pages/official/CreateOfficialPage";

const siteMap = {
    LandingPage: {
        title: 'Home',
        path: '/',
        description: 'Landing Page'
    },
    Login: {
        title: 'Login',
        path: '/login',
        description: 'Login Page'
    },
    Contact: {
        title: 'Kontakt',
        path: '/contact/:id',
        description: 'Kontakt'
    },

    Editor: {
        title: 'Editor',
        path: '/contact/:id/edit',
        pathWithoutParams: '/contact',
        description: 'Editor'
    },

    SpecificRegion: {
        title: 'Region',
        path: '/region/:id',
        description: 'Region'
    },

    CreateOfficial: {
        title: 'Ansprechpartner anlegen',
        path: 'region/:id/contact/add'
    }

}

export const AppRoutes: React.FC = () => {
    return (
            <>
                <Routes>
                    <Route path="/404" element={<Error404Page/>}/>


                    <Route path={siteMap.Editor.path} element={<PrivateRoute component={EditorPage} roles={''}/>}/>
                    <Route path={siteMap.Login.path} element={<LoginPage/>}/>


                    <Route path={siteMap.LandingPage.path} element={<LandingPage/>}/>
                </Routes>

                <OfficialService>
                    <Routes>
                        <Route path={siteMap.SpecificRegion.path} element={<PrivateRoute component={RegionDetailPage} roles={''}/>}/>
                        <Route path={siteMap.CreateOfficial.path} element={<PrivateRoute component={CreateOfficialPage} roles={''}/>}/>
                        <Route path={siteMap.Contact.path} element={<PrivateRoute component={OfficialPage} roles={''}/>}/>
                    </Routes>
                </OfficialService>
            </>
    );
}

export default function useRoutes() {
    return siteMap;
}