import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import API from "../../../Request/API";
import {toast} from "react-toastify";
import {Source} from "../../esm";
import useError from "../../services/ErrorContext";

const CreateOfficialPage: React.FC = () => {
    const {register, watch, handleSubmit, formState: {errors}} = useForm({mode: "onChange"});
    const [sources, setSources] = useState<Source[]>([]);
    const {contact, source} = watch();
    const {setCurrentError} = useError();
    const navigate = useNavigate();
    const {id} = useParams();

    const handleSubmitOfficial = () => {
        const newData = {
            'geo_id': id,
            ...contact,
            ...source,
            last_checked: new Date(),
            query_params: []
        };

        API.post('/api/contact/create', newData, {withCredentials: true})
                .then(() => {
                    toast.success('Ansprechpartner hinzugefügt', {
                        position: "bottom-right",
                        autoClose: 2250,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        theme: "colored"
                    });

                    navigate(`/region/${id}`, {replace: true});
                })
                .catch(error => {
                    setCurrentError(error.response)
                });
    }

    // fetch existant sources
    useEffect(() => {
        API.get(`/api/source?geo_id=${id}`, {withCredentials: true})
                .then(r => {
                    setSources(r.data.payload);
                })
                .catch(error => {
                    setCurrentError(error.response)
                });
    }, [id])

    return (
            <section className="pt-6 pt-md-8 pb-8 mb-md-8">

                <Container>
                    <Row>
                        <h1>Ansprechpartner hinzufügen</h1>
                    </Row>

                    <Form onSubmit={handleSubmit(handleSubmitOfficial)}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="name"> Name </Form.Label>
                                <Form.Control
                                        {...register('contact.name')}
                                        type="text"
                                        id="name"
                                        placeholder="Name des Ansprechpartners"
                                        autoComplete="off"
                                />
                            </Form.Group>

                            {/*<Form.Group as={Col}>*/}
                            {/*    <Form.Label htmlFor="name"> Nachname </Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*            {...register('contact.lastname')}*/}
                            {/*            type="text"*/}
                            {/*            id="lastname"*/}
                            {/*            placeholder="Nachname des Ansprechpartners"*/}
                            {/*            autoComplete="off"*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}
                        </Row>

                        <Form.Group className={'my-3'}>
                            <Form.Label htmlFor="sex"> Geschlecht </Form.Label>
                            <Form.Select
                                    {...register('contact.sex')}
                                    aria-label="Default select example"
                                    id={"sex"}
                            >

                                <option value="u">Keine Angabe</option>
                                <option value="m">Männlich</option>
                                <option value="f">Weiblich</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className={'my-3'}>
                            <Form.Label htmlFor="position">Position </Form.Label>
                            <Form.Control
                                    {...register('contact.position')}
                                    type="text"
                                    id="position"
                                    placeholder="Position des Ansprechpartners"
                                    autoComplete="off"/>
                        </Form.Group>

                        <Row>
                            <Form.Group as={Col} className={'my-3'}>
                                <Form.Label htmlFor="phone">Telefon</Form.Label>
                                <Form.Control {...register('contact.phone', {pattern: /^([0-9 ()\/+-]*)$/})}
                                              type="tel"
                                              id="phone"
                                              placeholder="Telefonnummer des Ansprechpartners"
                                              autoComplete="off"
                                              isInvalid={(errors.contact?.phone) ?? false}
                                />
                                {errors.contact?.phone && <p className={"error"}>Gültige Zeichen: [0-9], "-", "+", "/" </p>}
                            </Form.Group>

                            <Form.Group as={Col} className={'my-3'}>
                                <Form.Label htmlFor="email"> E-Mail </Form.Label>
                                <Form.Control
                                        {...register('contact.email')}
                                        type="email"
                                        id="email"
                                        placeholder="E-Mail des Ansprechpartners"
                                        autoComplete="off"/>
                            </Form.Group>
                        </Row>

                        <Form.Group className={'my-3'}>
                            <Form.Label htmlFor="source">
                                <span style={{color: "red"}}>*</span> Quelle
                            </Form.Label>
                            <Form.Select
                                    {...register('source.source_id')}
                                    aria-label="Default select example"
                                    id={"source.id"}
                            >
                                <option value={-1}>Bitte wählen...</option>
                                {sources.map((source) => {
                                    return <option key={source.source_id}
                                                   value={source.source_id}>
                                        [{source.type.toUpperCase()}] {source.link}
                                    </option>
                                })}


                            </Form.Select>
                            <p className="m-3">oder</p>

                            <Form.Control
                                    {...register('source.manual_source')}
                                    type="text"
                                    id="position"
                                    placeholder="URL zur Quelle"
                                    autoComplete="off"/>

                            {((Number(source?.source_id) <= 0 && source.manual_source === "") || (Number(source?.source_id) > 0 && source.manual_source !== ""))
                                    &&
                                    <p className="my-3">Sie müssen eine Quelle auswählen <b>oder</b> eine neue Quelle angeben um die Auswahl zu speichern.</p>}
                        </Form.Group>

                        <div className={'d-flex align-items-center'}>
                            <Button variant={'secondary'} size={'sm'} onClick={() => navigate(-1)}>
                                Zurück
                            </Button>

                            <Button type={'submit'}
                                    variant={'secondary'}
                                    size={'sm'}
                                    className={'ms-3'}
                                    disabled={(Number(source?.source_id) <= 0 && source.manual_source === "")
                                            || (Number(source?.source_id) > 0 && source.manual_source !== "")
                                            || errors.contact !== undefined}
                            >
                                Speichern
                            </Button>
                        </div>
                    </Form>
                </Container>
            </section>
    );
}

export default CreateOfficialPage;