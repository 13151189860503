import React from "react";
import {Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";
// @ts-ignore
import foxyBytesMint from "../../assets/img/foxy-bytes-mint.svg";

const Footer: React.FC = () => {
    return (


            <>
                <div className="position-relative">
                    <div className="shape shape-bottom shape-fluid-x text-dark">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/>
                        </svg>
                    </div>
                </div>

                <footer className="py-md-11 bg-dark">
                    <Container>
                        <Row>
                            <div className="col-8 col-md-3 col-lg-3">
                                <img src={foxyBytesMint} className="mb-5" style={{width: '80%'}} alt={""}/>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">


                                <h6 className="fw-bold text-uppercase text-gray-700">
                                    Projekte
                                </h6>


                                <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                                    <li className="mb-3">
                                        <a href="https://www.jobcube.de/" className="text-reset" target="_blank">
                                            Job Cube
                                        </a>
                                    </li>
                                    <li className="mb-3">
                                        <a href="https://www.digitalerbelegversand.de/" className="text-reset" target="_blank">
                                            Digitaler Belegversand
                                        </a>
                                    </li>
                                </ul>

                            </div>
                            <div className="col-12 col-md-3 col-lg-3">

                                <h6 className="fw-bold text-uppercase text-gray-700">
                                    Blog
                                </h6>


                                <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                                    <li className="mb-3">
                                        <a href="/ImpressNER/demo" className="text-reset" data-scroll="{&quot;offset&quot;: 0}">
                                            NER gestützte Extraktion von Impressumsdaten
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">

                                <h6 className="fw-bold text-uppercase text-gray-700">
                                    Foxy Bytes GmbH
                                </h6>

                                <p className="text-gray-600 mb-2">
                                    Roßbergring 175<br/>
                                    D-64354 Reinheim<br/><br/>

                                    +49 (0)6071 82993-20<br/>
                                    info@foxybytes.de
                                </p>

                                <ul className="list-unstyled list-inline list-social my-6 mb-md-0">
                                    <li className="list-inline-item list-social-item me-3">
                                        <a href={'https://www.instagram.com/foxybytesgmbh/'} className="text-decoration-none"
                                           target="_blank">
                                            <FontAwesomeIcon icon={faInstagram} className={'fab fax text-gray-600'}/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item list-social-item me-3">
                                        <a href="https://twitter.com/foxy_bytes" className="text-decoration-none" target="_blank">
                                            <FontAwesomeIcon icon={faTwitter} className={'fab fax text-gray-600'}/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item list-social-item">
                                        <a href="https://www.linkedin.com/company/foxy-bytes/" className="text-decoration-none"
                                           target="_blank">
                                            <FontAwesomeIcon icon={faLinkedin} className={'fab fax text-gray-600'}/>
                                        </a>
                                    </li>
                                </ul>

                            </div>

                            <div className="col-12 text-gray-700 mt-5">
                                Eingetragen beim Amtsgericht Darmstadt, HRB 92396 | Geschäftsführer: Lukas Friedrich, Detlef Sauer | USt-IdNr.: DE290519603
                            </div>
                        </Row>

                    </Container>
                </footer>
            </>
    );
}

export default Footer;