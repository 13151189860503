import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";

const Error404Page: React.FC = () => {
    return (

            <Container className="d-flex flex-column">
                <Row className="align-items-center justify-content-center gx-0 min-vh-100">
                    <Col sm={12} md={5} lg={4} className="py-8 py-md-11">

                        <h1 className="display-3 text-center fw-bold">
                            Uh Oh.
                        </h1>

                        <p className="mb-5 text-center text-muted">
                            Wir sind auf ein Problem gestoßen, aber keine Sorge, wir werden uns sicher darum kümmern.
                        </p>

                        <div className={'text-center'}>
                            <Button variant={'secondary'} className="btn btn-primary" href={'#'}>
                               Zurück
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}

export default Error404Page;