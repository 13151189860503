import React, {useEffect, useRef, useState} from "react";
import ReactQuill, {Quill} from "react-quill";
import {Button, Col, Container, Row} from "react-bootstrap";
import apiUrl from "../../../app";
import API from "../../../Request/API";
import {useParams} from "react-router-dom";


let Inline = Quill.import('blots/inline');
let lastcount = 0;

// annotation blocks
function annotate(str: string) {
    return str
            .replace(/<([A-Z]+)>(.*?)<\/\1>/g, '&lt;$1&gt;<div class="annotation" data-tag="$1">$2</div>&lt;/$1&gt;')
            .replace(/<([A-Z]+)>/g, "&lt;$1&gt;");
}

function debounce(fn: any, timeout = 400) {
    let idleTimer: any = null;

    return (...args: any[]) => {
        clearTimeout(idleTimer);
        idleTimer = setTimeout(() => {
            return fn(...args);
        }, timeout);
    }
}

class AnnotationBlot extends Inline {
    static colors = {
        'FIRMA': 'skyblue',
        'NAME': 'lightcoral',
        'ADDRESSE': 'lightgreen',
        'PERSON': 'lightsalmon',
        'POSITION': '#CCCCFF',
        'ABTEILUNG': '#40E0D0',
        'EMAIL': '#ccce5d',
        'TELEFON': '#348dc1',
        'WWW': '#c88741',
        'REFERENZ': '#afafaf',
        'ANREDE': '#7be777'
    }
    static className: string;
    static tagName: string;
    static blotName: string;

    static create(value: any) {
        let node = super.create();
        node.setAttribute('data-tag', value);
        // @ts-ignore
        node.setAttribute('style', '--tag-col: ' + (AnnotationBlot.colors[value] || '#5b554f'));

        return node;
    }

    static formats(node: any) {
        return node.getAttribute('data-tag');
    }

    static setColors(colors: any) {
        AnnotationBlot.colors = colors;
    }
}

AnnotationBlot.blotName = 'annotation';
AnnotationBlot.tagName = 'div';
AnnotationBlot.className = 'annotation';

Quill.register(AnnotationBlot);

const update = (quill: Quill) => debounce((_pre: any, _post: any, source: any) => {
    if (source === 'silent')
        return false;

    // check if number of matches changed first
    const text = quill.getText();

    // @ts-ignore
    const matches = [...text.matchAll(/<([/]?)([^>]+)>/g)];
    const tags = matches.map(match => match[0].length).reduce((a, b) => a + b, 0);

    if (tags === lastcount || tags === 0)
        return;

    lastcount = tags;

    // remove formatting
    quill.removeFormat(0, text.length - 1, 'silent');

    // annotate tags
    let open = null;
    let start = 0;
    for (const match of matches) {

        if (match[1]) {
            // end tag
            if (open === match[2]) {
                // -> format tag content
                quill.formatText(start, match['index'] - start, 'annotation', match[2], 'silent');
            } else {
                // wrong end tag
                // -> mark both tags as error
                if (open)
                    quill.formatText(start - open.length - 2, open.length + 2, 'annotation', 'ERROR', 'silent');
                quill.formatText(match['index'], match[0].length, 'annotation', 'ERROR', 'silent');
            }

            open = null;
        } else {
            // start tag
            if (open !== null) {
                // unexpected open tag
                // -> mark previous open as error
                quill.formatText(start - open.length - 2, open.length + 2, 'annotation', 'ERROR', 'silent');
            }

            // -> remember start pos
            open = match[2];
            start = match['index'] + match[0].length;
        }
    }

    if (open !== null)
        quill.formatText(start - open.length - 2, open.length + 2, 'annotation', 'ERROR', 'silent');
});

// Register
Quill.register('modules/reannotate', function (quill: any, _: any) {
    quill.on('text-change', update(quill));
});


type TextEditorProps = {
    disabled: boolean,
    text: string,
    link?: string,
}


const TextEditor: React.FC<TextEditorProps> = (props: TextEditorProps) => {
    const [value, setValue] = useState('');
    const [init, setInit] = useState(false);
    const {id} = useParams();

    const {text, disabled, link} = props;
    const quillRef = useRef(null);

    useEffect(() => {
        Quill.register('modules/reannotate', function (quill: any, _: any) {
            quill.on('text-change', update(quill));
        });
        setValue(text)
    }, [])


    if (!init && quillRef.current !== null) {
        // @ts-ignore
        quillRef.current.getEditor().setText(text)

        if (disabled)
                // @ts-ignore
            quillRef.current.getEditor().enable(false);

        setInit(true);
    }

    const handleSubmit = async () => {
        if (quillRef.current === null)
            return;

        // @ts-ignore
        const text: string = quillRef.current.getEditor().getText();
        const response = await API.post(
                `${apiUrl}/api/updateText`,
                {'official_id': id, 'text': text},
                {
                    withCredentials: true
                });
        console.log(response);
    }

    const modules = {
        toolbar: false,
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
        reannotate: true,
    }

    return (
            <Container>
                <Row className={'p-5 d-flex align-content-center'}>
                    <Col>
                        <h2 className={'m-0'}>Extrahierter Text</h2>
                    </Col>
                    {link && <Col className={'d-flex justify-content-end'}>
                        <Button variant={'info'}
                                className={'float-right'}
                                size={'sm'}
                                href={link}
                                target={'_blank'}
                        >
                            Zur Originalquelle
                        </Button>
                    </Col>}
                </Row>
                <ReactQuill ref={quillRef} theme={'snow'} value={value} onChange={setValue} modules={modules}/>

                {!disabled && <Row>
                    <Button variant={'secondary'} onClick={handleSubmit}>Speichern</Button>
                </Row>}
            </Container>
    );
}

export default TextEditor;