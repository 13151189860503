import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


class LandingPage extends Component {
    render() {
        return (
            <section className="row-section" style={{height: '100vh'}}>
                <Container>

                    <Row>
                        <Col>
                            <h2 className="text-center">Hello world</h2>

                            <h1 className="text-center">Probiere es <a href={'/region/931'}><b>hier</b></a> oder <a href={'/region/3298'}><b>hier</b></a></h1>
                        </Col>
                    </Row>

                </Container>
            </section>
        )
    }
}

export default LandingPage;
