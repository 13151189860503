import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import TextEditor from "../components/Editor/TextEditor";
import API from "../../Request/API";
import useError from "../services/ErrorContext";


const EditorPage: React.FC = () => {
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string | undefined>();
    const {setCurrentError} = useError();
    const {id} = useParams();


    // Fetch data and set form values
    useEffect(() => {
        // TODO: Move to context
        API.get(`/api/contact/${id}`, {
            withCredentials: true
        }).then(res => {
            const payload = res.data.payload;
            setText(payload.text);
            setLink(payload.link);
        }).catch(error => {
            setCurrentError(error.response)
        });
    }, []);


    return (
            <section className="pt-6 pt-md-8 pb-8 mb-md-8">
                <Container fluid>
                    {text && <TextEditor disabled={false} text={text} link={link}/>}
                </Container>
            </section>
    );
}

export default EditorPage;