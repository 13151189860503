import React, {useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useRoutes from "../../../Routes";
import {LoginState} from "./LoginState";
import API from "../../../Request/API";


export const NavigationBar: React.FC = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const routes = useRoutes();
    const navigate = useNavigate();

    const nextRegion = () => {
        API.get(`/api/region/next`, {
            withCredentials: true
        }).then(res => {
            const payload = res.data.payload;
            const {region} = payload;

            (region === null)
            ? navigate(routes.LandingPage.path, {replace: true}) 
            : navigate(`/region/${region.geo_id}`)

        }).catch(error => {
            console.log(error);
        });
    }

    return (
            <Navbar variant={"dark"}
                    bg={"dark"}
                    expand={'lg'}
                    expanded={expanded}
            >
                <Container fluid>
                    <Navbar.Brand as={Link}
                                  className={'navbar-brand'}
                                  to={routes.LandingPage.path}
                    >
                        Savills Contacts
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls={'responsive-navbar-nav'}
                                   onClick={() => setExpanded(!expanded)}/>

                    <Navbar.Collapse id={'responsive-navbar-nav'}
                                     style={{padding: '1rem', backgroundColor: ' #1b2a4e'}}
                    >
                        <Nav className="my-2 my-lg-0" style={{maxHeight: '100px'}} navbarScroll>
                            <Nav.Link as={Link} to={'#'} onClick={nextRegion}>Review</Nav.Link>
                        </Nav>


                        <LoginState/>
                        <Navbar.Toggle aria-controls={'responsive-navbar-nav'}
                                       onClick={() => setExpanded(!expanded)}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Navbar.Toggle>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    );
}