import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import API from "../../Request/API";
import {toast} from "react-toastify";
import {BasicContext, Official, Region, User} from "../esm";
import useError from "./ErrorContext";
import {useNavigate} from "react-router-dom";

interface OfficialContextType extends BasicContext {
    officials: Official[],
    region?: Region,
    updateLastChecked: (id: number) => void,
    fetchOfficialsByRegionId: (id: number) => void,
}

const OfficialContext = createContext<OfficialContextType>({} as OfficialContextType);

export const OfficialService: React.FC = ({children}) => {
    const {setCurrentError} = useError();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState();
    const [officials, setOfficials] = useState<[]>([]);
    const [region, setRegion] = useState<Region>();
    const navigate = useNavigate();


    const fetchOfficialsByRegionId = (id: number) => {
        if (!isLoading) {
            setIsLoading(true);

            API.get(`/api/region/${id}`,
                    {withCredentials: true}
            ).then(r => {
                const payload = r.data?.payload;
                setOfficials(payload.officials);
                setRegion(payload.region);
                setIsLoading(false);
            }).catch(error => {
                setCurrentError(error.response)
            });
        }
    }

    const updateLastChecked = (id: number) => {
        API.post(
                '/api/region/check',
                {geo_id: id},
                {withCredentials: true}
        ).then(r => {
            const payload = r.data.payload;

            if (region === undefined)
                return;

            let tmpRegion = region;
            tmpRegion.lastChecked = payload.lastChecked;
            tmpRegion.user = {username: payload.username};
            //setRegion(tmpRegion);


            toast.success('Speichern erfolgreich!', {
                position: "bottom-right",
                autoClose: 2250,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored"
            });
        }).catch(error => {
            setCurrentError(error.response)
        }).finally(() => {

            API.get(`/api/region/next`, {
                withCredentials: true
            }).then(res => {
                const payload = res.data.payload;
                const {region} = payload;

                if (region)
                    // TODO: there must be a nicer way
                    navigate(`region/${region.geo_id}`)
                fetchOfficialsByRegionId(region.geo_id);
            }).catch(error => {
                console.log(error);
            });


        });
    }


    useEffect(() => {
    }, [region])

    const memorizedData = useMemo(() => ({
        officials, region, isLoading, errors, setOfficials, updateLastChecked, fetchOfficialsByRegionId,
    }), [officials, isLoading, errors, region])

    return (
            <OfficialContext.Provider value={memorizedData}>
                {children}
            </OfficialContext.Provider>
    );
}

export default function useOfficials() {
    return useContext(OfficialContext)
};