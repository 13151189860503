import React from "react";
import {Button, Nav} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import useRoutes from "../../../Routes";
import useAuth from "../../../Auth/services/AuthService";

export const LoginState: React.FC = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const routes = useRoutes();

    return (
            <Nav className={'ms-auto'}>
                {auth.username && auth.authentication &&
                <Nav.Item className={'nav-link'}>Eingeloggt als: {auth.username}</Nav.Item>}

                {auth.authentication &&
                <Nav.Link as={Button} variant={'info'} onClick={auth.logout}>Logout</Nav.Link>}

                {!auth.authentication &&
                <Nav.Link as={Button}
                          variant={'info'}
                          onClick={() => navigate(routes.Login.path)}>
                    Login
                </Nav.Link>}
            </Nav>
    );
}